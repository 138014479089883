<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <Sidebar />
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <router-view :key="$route.path" />
      </main>
    </div>
  </div>
</template>

<script>
import {appItems} from './menu-items';
import Sidebar from '@/components/Sidebar';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      items: appItems,
    };
  },
};
</script>
