<template>
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div class="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
      <div class="flex items-center flex-shrink-0 justify-center px-6">
        <h3 class="h-8 w-auto text-4xl">{{ name ?? 'WHFS' }}</h3>
      </div>
      <div class="h-0 flex-1 flex flex-col overflow-y-auto">
        <Menu as="div" class="px-3 mt-6 relative inline-block text-left">
          <div>
            <MenuButton
              class="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
            >
              <span class="flex w-full justify-between items-center">
                <span class="flex min-w-0 items-center justify-between space-x-3">
                  <img
                    class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                    src="@/assets/png/default-avatar.png"
                    alt=""
                  />
                  <span class="flex-1 flex flex-col min-w-0">
                    <span class="text-gray-900 text-sm font-medium truncate">{{
                      userName[0]
                    }}</span>
                    <span class="text-gray-500 text-sm truncate">{{ userName[1] }}</span>
                  </span>
                </span>
                <SelectorIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem>
                  <amplify-sign-out button-text="Logout"></amplify-sign-out>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <!-- Navigation -->
        <nav class="px-3 mt-6">
          <div class="space-y-1">
            <RouterLink
              :class="[
                getActive(item.href)
                  ? 'text-gray-500 bg-gray-200'
                  : 'text-gray-400 group-hover:text-gray-500',
              ]"
              v-for="item in navigation"
              :key="item.name"
              :name="item.href"
            >
              <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
              {{ item.name }}
            </RouterLink>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {
  HomeIcon,
  DocumentReportIcon,
  MapIcon,
  SelectorIcon,
  UserIcon,
} from '@heroicons/vue/outline';
import {onAuthUIStateChange} from '@aws-amplify/ui-components';
import env from '@/config/env';
import {mapGetters, mapMutations} from 'vuex';
import RouterLink from '@/components/RouterLink';

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    RouterLink,
    SelectorIcon,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      group: 'app/getGroup',
    }),
    name() {
      return env.name;
    },
    userName() {
      return this.user && this.user.email ? this.user.email.split('@') : [];
    },
    getCurrentRoute() {
      return this.$route.name;
    },
    navigation() {
      let items = [
        {name: 'Home', href: 'home', icon: HomeIcon, current: false},
        {name: 'Maps', href: 'maps', icon: MapIcon, current: false},
        {name: 'Surveys', href: 'surveys', icon: DocumentReportIcon, current: false},
      ];
      if (this.userGroup && this.userGroup.includes('Admin')) {
        items.push({name: 'Users', href: 'users', icon: UserIcon, current: false});
      }
      return items;
    },
  },
  data() {
    return {
      userGroup: null,
    };
  },
  methods: {
    ...mapMutations({
      logout: 'auth/LOGOUT',
    }),
    getActive(route) {
      return this.getCurrentRoute.includes(route);
    },
  },
  async created() {
    this.userGroup = await this.group;
    this.unsubscribeAuth = onAuthUIStateChange(async () => {
      this.logout();
      this.$router.push({name: 'auth.login'});
    });
  },
};
</script>
